/*
 * topbutton.js
 * (C)2014 SHINGI Corporation.
 */
(function($) {
	"use strict";

	$.fn.topButton = function(args) {
		var defaults = {
			// scrollTopがどれくらいになったらトップボタンを表示するか
			threshold : 10,

			// スクロールの際の所要時間(ミリ秒)
			scrollspeed : 500,

			// 表示、非表示の切り替え所要時間(ミリ秒)
			duration : 300
		};
		var options = $.extend(true, {}, defaults, args);
		return this.each(function() {
			var tb = $(this);
			tb.click(function() {
				$("html,body").animate({
					scrollTop : 0
				}, options["scrollspeed"]);
			});
			var fScroll = function() {
				if (options["threshold"] <= $(this).scrollTop()) {
					tb.fadeIn(options["duration"]);
				} else {
					tb.fadeOut(options["duration"]);
				}
			};
			$(window).scroll(fScroll);
			fScroll();
		});
	};
})(jQuery);

$(function() {
	$("#topbutton").topButton();
});
